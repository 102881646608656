@import url('https://fonts.googleapis.com/css?family=Poppins:300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

// Grid settings
$enable-flex: true;
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
// $grid-gutter-width-base:    2px;
// $enable-rounded:            true;
// $baseBorderRadius:          2px;


$main-font : 'Poppins',
sans-serif;
;


$sally-bg-gradient : -webkit-linear-gradient(-180deg, #f56912 0, #cf0c51 100%);





// Colors Styles for loops
$main-color: #f56912;
$brand-primary: #4e78fe;
$brand-green: #009645;
$brand-black: #000000;
$brand-gray: #242939;
$brand-white: #ffffff;



// list with all theme colors to be reused in @each
$theme-colors: // color name       color brand               text color      secondry text color
"main"$main-color $brand-white $main-color,
"primary"$brand-primary $brand-primary $brand-white,
"green"$brand-green $brand-black $brand-green,
"black"$brand-black $brand-white $brand-black,
"gray"$brand-gray $brand-white $brand-gray,
"white"$brand-white $brand-black $brand-white,
;
.table-user-section {
    display: flex;
}

.table-user-section table {
    text-align: center;
}

.user-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.user-information {
    margin: auto 0px auto 10px;
}

.user-information h4 {
    margin-bottom: 0px !important;
    font-size: 14px;
}

.user-information .info {
    font-size: 12px;
    display: block;
    color: #9a9a9a;
}

.user-information .info .badge {
    font-size: 13px;
}

.table-responsive {
    height: 800px;
}

table {
    color: $brand-gray;
    font-size: 13px;
    font-weight: 500;
    border-collapse: collapse;

    tr {
        td {
            vertical-align: middle !important;

            img {
                width: 22px;
                border-radius: 3px;
            }


        }

        &:hover {
            .mes-icon {
                fill: #208ef0;
            }
        }
    }

    thead {
        th {
            font-weight: 500;
            color: #464646;
            position: sticky;
            top: -1px;
            background: #ececec;
        }
    }

}

.messenger-td {
    text-align: center;
}

.messenger-logo {
    width: 30px;
}

span.label {
    padding: 5px 25px 5px 5px;
    font-size: 10px;
    border-radius: 5px;
    color: $brand-gray;
    background: #f7f8fa;
    border: 1px solid #bfc3ca;
    margin: 5px 5px;
    position: relative;
    font-weight: 600;
    cursor: context-menu;

    &:hover {
        background-color: $brand-gray;
        color: $brand-white;

        .close {
            color: $brand-white;
        }
    }

    .close {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 10px;
        padding: 1px;

        &:hover {
            color: red;
        }
    }
}

.table-responsive {
    & ::-webkit-scrollbar {
        width: 10px !important;
    }

    &::-webkit-scrollbar-track {
        background: red !important;
    }

    &::-webkit-scrollbar-thumb {
        background: yellow !important;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: blue !important;
    }
}

.add-btn {
    top: 14px;
    right: 14px;
    margin: 5px;
}
.pauser-th {
z-index: 99;
}

.custom-btn-main {
    background-color: $brand-white;
    color: $brand-gray;
    font-weight: bold;
    height: 30px;
    width: 30px;
    text-align: center;
    padding: 0rem 0.4rem;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 1px #b7b7b7;

    svg {
        width: 0.7em !important;
    }
}

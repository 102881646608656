.app-modal {
  background-color: #141420;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9991;
}

.app-modal-inner {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  width: 90%;
  z-index: 9992;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.card-img-top {

  max-height: 250px;
}

.modal-button {
  width: 100%;
  margin: 15px 0;
  background-color: #141420;
  color: white;
}

.modal-button:hover {
  background-color: #141440;
  color: white;
}
.card{
  float:left;
}
.padding-top-65{
  padding-top: 65px;
}

* {
  &:focus {
    outline: none;
  }
}

body {
  font-family: $main-font;
  background: #ececec;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

.notification {
  width: 300px;
  height: 100vh;
  top: 50px;
  right: 50px;
  color: #ececec;
  z-index: 1;
  position: fixed;
  text-align: center;
  padding: 1em;
  margin: 1em;
  text-transform: capitalize;

}

.notification-item {
  margin: 1em;
  background-color: #5d78ff;
  padding: 1em;
  width: 100%;
  height: 100px;
  list-style: none;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}
// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600');
@import '~bootstrap/scss/bootstrap.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';

// Common
@import "assets/scss/common/_variables.scss";
@import "assets/scss/common/_helpers.scss";
@import "assets/scss/common/_loops.scss";
@import "assets/scss/common/_general.scss";


// Page
@import "assets/scss/pages/_people.scss";
@import "assets/scss/pages/_broadcast.scss";

// components
@import "assets/scss/components/_auth.scss";
@import "assets/scss/components/_form.scss";
@import "assets/scss/components/_header.scss";
@import "assets/scss/components/_layout.scss";
@import "assets/scss/components/_modal.scss";
@import "assets/scss/components/_sidebar.scss";
@import "assets/scss/components/_pagination.scss";
@import "assets/scss/components/_searchBuilder.scss";
@import "assets/scss/components/_newCard.scss";




// Global The Last one
@import "assets/scss/common/_global.scss";
.chat-new-message-component {
  display: grid;
  grid-template-columns: 50px auto;
  margin: 2em 0px;
}

.chat-new-message-component .card-input-body {
  border-radius: 5px;
  background-color: #fff;
  padding: 1em;
  width: 50%;
}

.chat-new-message-component .options {
  width: 14px;
  height: 14px;
  border: 0px !important;
  color: rgba($color: #000000, $alpha: .4);
  background-color: transparent;
  outline: none;
}

.chat-new-message-component .options:hover {
  color: rgba($color: #000000, $alpha: 1);
}

.list-group {
  margin: 1em 0;
}


.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.media-type,
.list-type {
  display: grid;
  grid-template-columns: auto;
  position: relative;
}

.media-type label {
  width: 100%;
  background-color: #eee;
  padding: 2em;
  display: grid;
  grid-template-rows: auto auto;
  text-align: center;
  cursor: pointer;
}

.media-type label.disabled {
  cursor: default;
  background-color: #fff;
}

.media-type label svg {
  margin: 0 auto 10px auto;
  font-size: 3em;
}

.inputfile:focus label,
.media-type label:hover {
  background-color: #2386fa;
  color: #fff;
}

.inputfile:focus label.disabled,
.media-type label.disabled:hover {
  background-color: #fff;
  color: rgb(204, 3, 3);
}

.typing-type-label {
  display: grid;
  grid-template-columns: auto auto;
}

.typing-type-label .spinner {
  margin: 0 !important;
}

.typing-type-label .spinner div {
  background-color: #4e78fe !important;
}

.card-list {
  display: grid;
  grid-template-columns: auto 30%;
  padding: 20px;
  margin: 10px 0;
  background-color: #ddd;
  border-radius: 5px;
  position: relative;
}

.card-list.cover {
  background-color: #989898;
}

.card-list.cover h4 {
  position: absolute;
  background-color: #ffffff;
  color: #989898;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  top: -30px;
  left: 74px;
  box-shadow: -9px 9px 24px -5px rgba(168, 168, 168, 1);
}

.card-list .list-information-left div {
  margin: 5px 5px 5px 0px;
}

.card-list .list-information-right div {
  margin: 5px 0;
}

.card-list .list-information-right .choose-image {
  width: 100%;
  height: 50%;
  border: 2px dotted #fff;
  color: #fff;
  border-radius: 5px;
  text-align: center;
}

.card-list .list-information-right .choose-image svg {
  margin: 15px;
  font-size: 2em;
}

.card-list .list-information-right .choose-image:hover {
  border: 2px dotted #000;
  color: #000;
}

.card-list .list-information-left .item-btn {
  border-radius: 2px;
  border: solid 1px #fff;
  background: transparent;
  font-size: 11px;
  line-height: 1;
  color: #fff;
  padding: 4px 8px;
  font-weight: 500;
  width: auto;
  height: auto;
  margin-left: 6px;
  display: block;
  float: left;
  min-height: 22px;
  cursor: pointer;
}

.card-list .list-information-left .item-btn:hover {
  border: solid 1px #4e78fe;
  color: #4e78fe;
}

div textarea {
  display: block;
  width: 100%;
  padding: 3px 6px 0;
  background-color: #ffffff;
  border: solid 1px transparent;
  resize: none;
  margin: 0;
  overflow: hidden;
  white-space: inherit;
  border-radius: 7px;
  font-size: 12px;
}

.btn-delete {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #eee;
}

.btn-delete:hover {
  -webkit-box-shadow: -9px 9px 24px -5px rgba(168, 168, 168, 1);
  -moz-box-shadow: -9px 9px 24px -5px rgba(168, 168, 168, 1);
  box-shadow: -9px 9px 24px -5px rgba(168, 168, 168, 1);
  background-color: #4e78fe;
  color: #fff;
  border: 0;
}

button.btn-delete:disabled:hover {
  background-color: #4e77fe5b;
}

* {
  transition: all .3s ease-in-out;
  &.no-effect {
    transition: all 00s ease-in-out;
  }
}

main{
    // margin-top: 80px;
    display: flex;
    .wrap{
      padding: 20px;
      background-color: #fff;
      margin: 0 20px 20px 20px;
      border-radius: 5px;
    }
}

.content{
  width :100%;
  min-height: 100vh;
  padding-left: 20%;

  &.smallNav{
    padding-left: 5%;
  }
}

header{
  background: #fff;
  margin-bottom: 20px;
  nav {
    .nav-item{
      border-radius: 5px;
      display: flex;
      align-items: center;

      &:hover{
        transition: all 0.3s;
        background-color: #f0f3ff;
        a{
          color: $brand-gray;
        }
      }

      .nav-link {
        padding: 0.2rem;
      }
      svg{
        color: #b4b8ce;
        margin-right: 15px;
        font-size: 18px;
        margin-top: 5px;
        width: 0.7rem !important;
        height: 0.7rem !important;

        &:hover {
          color:#5d78ff;
        }
      }



      &.user-account{
        cursor: pointer;
        font-size: 12px;
        a{
          color: #959cb6;

          .user-img {
            width: 1.4rem;
            height: 1.4rem;
            display: inline-block;
            background-color: rgba($color: $main-color, $alpha: 0.5);
            color: $brand-white;
            font-weight: bold;
            justify-content: center;
            display: inline-flex;
            border-radius: 3px;
            align-items: center;
          }
        }
        .user-avatar{
          border-radius: 50%;
          max-width: 30px;
        }
      }

    }

  }
}

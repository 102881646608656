.side-nav {
  // box-shadow: 5px 0 2px -1px #888;
  padding: 5px 0;
  min-height: 100vh;
  width: 20%;
  position: fixed;
  background-color: #242939;

  .toggleNav {
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    right: 10px;
    outline: none;

    span {
      &.openNav {
        display: none;
      }

      &:hover {
        span g path {
          fill: #ec551f;
        }
      }
    }

    &.smallNav {
      .openNav {
        display: block;
      }

      .closeNav {
        display: none;
      }
    }
  }

  .logo {
    display: none;
  }

  .sally-text {
    margin-left: 12px;
    background: -webkit-linear-gradient(-180deg, #f56912 0, #cf0c51 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .nav-item {
    height: 45px;

    &.active,
    &:hover {
      background-color: #1f2433;
    }

    a {
      color: #c0c4d1;
      font-size: 14px;

      &.active,
      &:hover {
        color: $brand-white;

        svg {
          color: #5d78ff;
        }
      }
    }

    svg {
      color: #434d6b;
      margin-right: 15px;
      font-size: 18px;
    }
  }

  .menu__section-text {
    font-size: .83rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .3px;
    color: #606a8c;
    padding-left: 20px;

  }

  &.smallNav {
    width: 5%;

    .logo {
      display: block;
      max-width: 68px;
      margin-top: 50px;
    }

    .sally-text {
      display: none;
    }

    .menu__section-text {
      display: none;
    }

    .nav-item {
      svg {
        margin-right: 0;
      }

      a {
        padding: 0.5rem 0rem;
        text-align: center;

        .nav-text {
          display: inline-block;
          opacity: 0;
          width: 0;
        }
      }
    }
  }
}
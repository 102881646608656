.rc-pagination {
    float: right;
    margin-top: 20px;
}
.rc-pagination-item-active {
    background-color: #141420 !important;
    border-color: #141420 !important;
    color: #eee !important;
}
.rc-pagination-item:hover{
    background-color: #29293f !important;
    border-color: #29293f !important;
    color: #eee !important;
}

.rc-pagination-item:hover a{
    color: #eee !important;
}

.rc-pagination-jump-next , .rc-pagination-jump-prev:hover:after, .rc-pagination-jump-next:hover:after{
    color:  #29293f !important;
}
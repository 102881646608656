.sally-background {
  background: $sally-bg-gradient;
}

.fullvh{
  height: 100vh;
}

.fullvw{
  width: 100vw;
}

.fullh{
  height: 100%;
}

.fullw{
  width: 100%;
}

.spacer{
  height: 30px;
}

.pointer {
  cursor: pointer;
}

@each $theme-color in $theme-colors {
  $name: nth($theme-color, 1);
  $brand-color: nth($theme-color, 2);
  $brand-text: nth($theme-color, 3);
  $brand-text-secondry: nth($theme-color, 4);
  @include text-emphasis-variant('.text-#{$name}', $brand-color);
  @include bg-variant('.bg-#{$name}', $brand-color);

  .bg-#{$name} {
    color: $brand-text;

    .text-secondry-color {
      color: $brand-text-secondry;
    }

    a {
      color: $brand-text;

      &.text-secondry-color {
        color: $brand-text-secondry;
      }

      &:hover {
        color: darken($brand-color, 20%);
      }
    }
  }

  .btn-#{$name} {
    @include button-variant($brand-text, $brand-color, darken($brand-color, 5%));
  }

  .text-#{$name} {
    color: $brand-text;
  }


}
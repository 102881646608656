.login-page {
  background: url('assets/images/loginbg.png');
  background-size: contain;
}

.sallylogo{
  max-width: 200px;
  margin-top: 50%;
}

.sallydesc{
  color: #fff;
  width: 80%;
  margin-top: 50px;
}

.auth-details-btn{
  width: 40%;
  margin-top: 40px;
}


  // Small devices (landscape phones, 576px and up)
  @media (min-width: 1px) and (max-width: 767px) {
    .sally-background{
      display: none !important;    
    }
  }

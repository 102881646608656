form {
  input.form-control {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    padding-left: 0;
    font-size: 14px;
    width: 85%;
    background: transparent;

    &:focus {
      border-bottom: 1px solid $main-color;
      outline: 0 !important;
      box-shadow: none;
      background: transparent;
    }

    &[type="password"] {
      -webkit-text-security: square;
    }
  }

  .form-group {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }

  .btn {
    background-image: -webkit-linear-gradient(-180deg, $main-color 0, #cf0c51 100%);
    border: none;
    width: 40%;
    margin: 40px 0;
  }

  &:focus {
    border-bottom: 1px solid $main-color;
    outline: 0 !important;
    box-shadow: none;
    background: transparent;
  }

  h2 {
    font-weight: 300;
    font-size: 55px;
    margin-bottom: 80px;
    color: $main-color;
  }

  .has-error {
    color: $main-color;
  }
}

.search-select {
  .search-select-div__control .css-1492t68 {
      top: 17% !important;
    }
  .search-select-div__control .css-xp4uvy{
    top: 17% !important;
    }
  .search-select-div__value-container--is-multi{
    top: 66% !important;
  }
  .search-select-div__control {
    background: white !important;
    max-height: 30px !important;
    min-height: auto !important;
    padding: 5px !important;
    margin: 5px !important;
    border-radius: 10px;

    // border-bottom: 1px solid rgba($color: $brand-gray, $alpha: 0.3);

    .search-select-div__value-container {
      padding-left: 0;
    }

    .search-select-div__indicator-separator {
      display: none;
    }

    .search-select-div__single-value {
      font-weight: 600;
    }

    .search-select-div__indicator {
      padding: 4px;

      svg {
        width: 15px;
        height: 15px;
        ;
      }
    }
  }

  &.multi-select {
    .search-select-div__control {
      overflow: hidden;

      .search-select-div__value-container {
        max-height: 30px;
      }

      &:focus-within {
        max-height: max-content;

        .search-select-div__value-container {
          max-height: max-content;
        }
      }

      .search-select-div__multi-value {
        margin-bottom: 10px;

        .search-select-div__multi-value__remove {
          svg {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #4c4c4c78;
}
.input_area {
    background: white !important;
    max-height: 30px !important;
    min-height: auto !important;
    padding: 5px !important;
    margin: 5px !important;
    border-radius: 10px;
}

.chat_img img,
.incoming_msg_img img {
  max-width: 100%;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  height: 583px;
  box-sizing: border-box;
  border-right: 1px solid #e3e2e8;
}

.inbox_msg {
  border: 1px solid #eee;
  clear: both;
}

.top_spac {
  margin: 20px 0 0;
}


.recent_heading {
  float: left;
  width: 100%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 100%;
  position: relative;
}

.headind_srch {
  padding: 30px 29px 30px 20px;
  overflow: hidden;
  border-bottom: 1px solid #e0e0e0;
  background-color: white;
  border-left: 4px solid #5d78ff;

  &:hover {
    cursor: pointer;
  }
}

.recent_heading {
  h4 {
    color: #000;
    font-size: 21px;
    margin: auto;
    position: relative;

    span {
      position: absolute;
      right: 10px;
    }
  }
}

.srch_bar input {
  border-width: 0;
  width: 100%;
  padding: 35px 0 35px 52px;
  background: none;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;

  &:focus {
    border-bottom: 2px solid #4386fb;
    outline: 0 !important;
    box-shadow: none;
    background: transparent;
  }
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #70707080;
  font-size: 18px;
}

.srch_bar input::placeholder {
  color: #70707080;
}

.srch_bar .input-group-addon {
  position: absolute;
  left: 25px;
  top: 35px;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 85%;

  h5 {
    font-size: 15px;
    color: #464646;
    margin: 0 0 8px 0;
    margin-left: 50px;

    span {
      font-style: italic;
      font-size: 11px;

      &.chat_date {
        color: #9fa1b8;
      }

      &.chat_last_seen {
        color: #24293a70;
      }
    }
  }

  p {
    font-size: 14px;
    color: #000;
    margin: auto;
    text-transform: capitalize;

    span {
      display: inline-block;
      padding: 8px;
      border: 1px solid #999ec1;
      border-radius: 50%;
      background-color: white;
      margin-right: 15px;
      color: #999ec1;
      width: 35px;
      height: 35px;
      text-align: center;
      margin-top: 5px;
      position: relative;
      top: 15px;
    }
  }
}

.chat_img {
  float: left;
  width: 9%;
}


.chat_people {
  overflow: hidden;
  clear: both;
  display: flex;
  align-items: center;
}

.chat_list {
  border-bottom: 1px solid #e1e0e7;
  margin: 0;
  padding: 25px 16px;
  background-color: #f1f2f7;

  &:hover {
    background: #fdfdfd;
    cursor: pointer;
  }

  &.active_chat {
    background: #fdfdfd;

    .chat_ib {
      p {
        span {
          background-color: #4e78fe;
          color: white;
          border: #4e78fe;
        }
      }
    }
  }
}

.inbox_chat {
  height: 286px;
  overflow-y: scroll;
}

.incoming_msg {
  // background-color: #fff;
  margin-bottom: 15px;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
}

.received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 16px;
  margin: 0;
  width: 100%;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 10px;
  font-style: italic;
  margin: 8px 0 0;
}

.received_withd_msg {
  width: 57%;
}

.mesgs {
  float: left;
  width: 100%;
  // height: 600px;
}

.sent_msg p {
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  text-align: right;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  max-width: 46%;
  background: #05728f none repeat scroll 0 0;
  border-radius: 2px;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
  padding: 37px;
}







.type_msg {
  border-top: 1px solid #e1e0e7;
  position: relative;
  background-color: #f6f5fa;
}

.msg_send_btn {
  background: #4e78fe;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  width: 12%;
  height: 50px;
}

.messaging {
  padding: 0 0 50px 0;
}

.msg_history {
  height: 419px;
  overflow-y: auto;
  padding: 20px;
}

.dots {
  position: absolute;
  right: 0;
  top: 6px;
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
}

.dots::before,
.dots::after {
  content: '';
  position: absolute;
  top: 0px;
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
}

.dots::before {
  right: 20px;
}

.dots::after {
  right: 10px;
}

.mesgs-header img {
  width: 7%;
  margin-right: 25px;
}

.mesgs-header {
  padding: 16px 0px 17px 45px;
  overflow: hidden;
  border-bottom: 1px solid #e1e0e7;
  background-color: white;
}

.mesgs-header h3 {
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
}

.msg_file_btn {
  background: transparent;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 12%;
  top: 0;
  width: 12%;
  height: 100%;
  color: #a6a9be;
  border-left: 1px solid #e8e8f0;
}

.msg_file_btn i {
  transform: rotate(90deg);
}

.mesgs-header h3 span {
  position: absolute;
  right: 32px;
}

.icons svg {
  padding: 8px;
  background-color: rgb(236, 236, 236);
  border-radius: 50%;
  margin-left: 12px;
  color: #5d78ff;
  width: 30px !important;
  height: 30px;
}

.dot-btn {
  position: absolute;
  right: 35px;
  top: 34px;
  height: 20px;
  width: 30px;
  cursor: pointer;
}

.chat_ib span.active {
  background-color: #4e78fe !important;
  color: white;
  border: none;
}

button.msg_send_btn:disabled {
  background-color: rgba(78, 120, 254, 0.5);
}

.card-message {
  padding: 20px;
}

.message-types ul {
  margin: 1em !important;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 1em;
}

.message-types ul li {
  padding: 1em 2em;
  background-color: #ccc;
  margin: 0px auto;
  border-radius: 5px;
  margin: 5px;
  display: grid;
  grid-template-rows: auto auto;
  text-align: center;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
}


.message-types ul li svg {
  margin: 0 auto 5px auto;
}

.message-types ul li:hover {
  color: #fff;
  background-color: #2386fa;
  transition: all .2s ease-in-out;
}

.text-type {
  display: grid;
  grid-template-columns: auto;
  position: relative;
}

.text-type textarea {
  border: 1px solid #4386fb;
  border-radius: 5px;
  margin-bottom: 5px;
}


.add-new-button {
  width: 400px;
  max-height: 1400px;
  border-radius: 7px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px rgba(0, 0, 0, 0.09);
  z-index: 10;
  background-color: #fff;
  right: -150px;
  top: -35px;
  position: absolute;
  text-align: center;
  padding: 1em;
}

.add-new-button input {
  border: 1px solid #4386fb;
  border-radius: 5px;
  width: 80%;
  padding: 5px !important;
}

.create-button-list-types ul {
  list-style: none;
  display: flex;
}

.create-button-list-types ul li {
  margin: 0px 20px 10px 20px;
  cursor: pointer;
  color: #000;
}

.create-button-list-types ul li.active {
  border-bottom: 2px solid red;
}
